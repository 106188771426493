import "../scss/private.scss";
import { eventQBus } from "./utils/EventQBus";
import { initToggles } from "./utils/Toggle";
import registerWishlistButton from "./tiles/WishlistButton";
import registerCompare from "./tiles/Compare";
import registerLazyTile from "./tiles/LazyTile";
import { registerTileNameMaxHeightFix } from "./tiles/TileNameMaxHeightFix";
import registerTooltipInitializer from "./tiles/TooltipInitializer";
import registerDiscountBenefit from "./tiles/DiscountBenefit";

/*                              */
registerLazyTile();
registerTileNameMaxHeightFix();

eventQBus.on("assets.events.documentReady", () => {
  initToggles();
  registerWishlistButton();
  registerCompare();
  registerTooltipInitializer();
  registerDiscountBenefit();
});
